<template>
  <div>
    <b-card header="Search">
      <b-form-row>
        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <search
            placeholder="Country"
            :search-query.sync="filter"
            @refresh="refreshTable"
          />
        </b-col>
        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-form-row>
    </b-card>

    <b-card>
      <b-table
        ref="table"
        hover
        responsive
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        :items="items"
        :fields="tableColumns"
        :filter="filter"
        :filter-included-fields="filterOn"
        show-empty
        empty-text="No countries found"
        @filtered="onFiltered"
      >
        <template #cell(actions)="{ item }">
          <b-dropdown
            id="download-prayers-options"
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'country-settings-edit', params:{ id: item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'CountriesSettingsList',
  components: { Pagination, Search, SearchButton },
  data() {
    return {
      tableColumns: [
        { key: 'country', sortable: true },
        { key: 'calculation_method', sortable: true },
        { key: 'madhab', sortable: true },
        { key: 'high_latitude_rule_methods', sortable: true },
        { key: 'polar_circler_resolution', sortable: true },
        { key: 'actions' },
      ],
      filter: '',
      filterOn: ['country'],
      items: [],
    }
  },
  created() {
    this.getCountries()
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getCountries() {
      this.$prayers.get('/countries').then(res => {
        // eslint-disable-next-line no-underscore-dangle
        const countries = res.data.data
        this.pagination.totalRows = countries.length
        this.items = countries
      }).catch(() => [])
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">

</style>
